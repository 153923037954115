import React, { lazy } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { store } from './features/store';
import { Redirector } from './components/Redirector';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CompagnieConfrontate from './pages/CompagnieConfrontate';
import Home from './pages/Home';
import OnBoarding from './pages/OnBoarding';
import Team from './pages/Team';
import SingleTeam from './pages/SingleTeam';
import WorkWithUs from './pages/WorkWithUs';
import ThankYouPage from './pages/ThankYouPage';
import UploadBrokerDocuments from './pages/UploadBrokerDocuments';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import Login from './pages/Login/Login';
import ClientPage from './pages/ClientPage';

// import FormAuto from './pages/Forms/FormAuto';
// import ResultPage from './pages/ResultPage';
const Chat = lazy(() => import('./pages/Chat'));
const Blogs = lazy(() => import('./pages/Blogs'));
const SingleBlog = lazy(() => import('./pages/SingleBlog'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },

  {
    path: '/compagnie-confrontate',
    element: <CompagnieConfrontate />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/blog',
    element: <Blogs />,
  },
  {
    path: '/blog/:blogSlug',
    element: <SingleBlog />,
  },
  {
    path: '/team',
    element: <Team />,
  },
  {
    path: '/team/:teamSlug',
    element: <SingleTeam />,
  },
  // {
  //   path: '/auto/funnel',
  //   element: <FormAuto />,
  // },
  // {
  //   path: '/auto/result/:requestToken',
  //   element: <ResultPage />,
  // },
  {
    path: '/onboarding',
    element: <OnBoarding />,
  },
  {
    path: '/chat',
    element: <Chat />,
  },
  {
    path: '/collabora-con-noi',
    element: <WorkWithUs />,
  },
  {
    path: '/broker-documents/:token',
    element: <UploadBrokerDocuments />,
  },
  {
    path: '/thank-you',
    element: <ThankYouPage />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/cliente/:requestToken/:clientId',
    element: <ClientPage />,
  },
  {
    path: '/redirect',
    element: <Redirector />,
  },
]);

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>
  );
}

export default App;
